import React from "react";

const pagesRoutes = [
  {
    path: "/dashboard",
    component: React.lazy(() => import("./dashboard/dashboard")),
  },
  {
    path: "/create-products",
    component: React.lazy(() =>
      import("./product/createProduct/createProducts")
    ),
  },
  {
    path: "/product-list",
    component: React.lazy(() => import("./product/productList/productList")),
  },
  {
    path: "/create-jobs",
    component: React.lazy(() => import("./jobs/createJobs")),
  },
  {
    path: "/schedule-jobs",
    component: React.lazy(() => import("./jobs/scheduleJobs")),
  },
  {
    path: "/not-schedule-jobs",
    component: React.lazy(() => import("./jobs/notSheduledJobs")),
  },
  {
    path: "/pending-jobs",
    component: React.lazy(() => import("./jobs/pendingJobs")),
  },
  {
    path: "/completed-jobs",
    component: React.lazy(() => import("./jobs/completedJobs")),
  },

  {
    path: "/create-warranty",
    component: React.lazy(() => import("./warranty/createWarrenty")),
  },
  {
    path: "/create-quotation",
    component: React.lazy(() => import("./quotation/createQuotation")),
  },
  {
    path: "/create-spare-parts",
    component: React.lazy(() => import("./spareParts/createSpareParts")),
  },
  {
    path: "/spare-parts-list",
    component: React.lazy(() => import("./spareParts/sparePartsList")),
  },
  {
    path: "/update/spare-parts/:spareId",
    component: React.lazy(() => import("./spareParts/updateSpareParts")),
  },
  {
    path: "/update-product/:proId",
    component: React.lazy(() =>
      import(".//product/createProduct/updateProduct")
    ),
  },
  {
    path: "/warranty-list",
    component: React.lazy(() => import("./warranty/warrantyList")),
  },
  {
    path: "/update-warranty/:warId",
    component: React.lazy(() => import("./warranty/updateWarranty")),
  },
  {
    path: "/update-job/:jobId",
    component: React.lazy(() => import("./jobs/udpateJobs")),
  },
  {
    path: "/register-technician",
    component: React.lazy(() => import("./technician/registerTechnician")),
  },
  {
    path: "/technician-list",
    component: React.lazy(() => import("./technician/techList")),
  },

  {
    path: "/update-technician/:techId",
    component: React.lazy(() => import("./technician/updateTechnician")),
  },
  {
    path: "/password",
    component: React.lazy(() => import("./general/password")),
  },
  {
    path: "/profile",
    component: React.lazy(() => import("./general/profile")),
  },
  {
    path: "/quotation-list",
    component: React.lazy(() => import("./quotation/quatationList")),
  },
  {
    path: "/update-quotation/:qId",
    component: React.lazy(() => import("./quotation/updateQuotation")),
  },
  {
    path: "/create-invoice",
    component: React.lazy(() => import("./invoice/createInvoice")),
  },
  {
    path: "/invoice-list",
    component: React.lazy(() => import("./invoice/invoiceList")),
  },
  {
    path: "/create-agreement",
    component: React.lazy(() => import("./agreement/createAgreement")),
  },
  {
    path: "/agreement-list",
    component: React.lazy(() => import("./agreement/agreementList")),
  },
  {
    path: "/visit-alert-list",
    component: React.lazy(() => import("./agreement/meetingAlertList")),
  },
  {
    path: "/agreement-expire-list",
    component: React.lazy(() => import("./agreement/agreementExpireList")),
  },
  {
    path: "/update-agreement/:agId",
    component: React.lazy(() => import("./agreement/updateAgreement.jsx")),
  },
  {
    path: "/create-template",
    component: React.lazy(() =>
      import("./termstemplate/createTemp.jsx")
    ),
  },
  {
    path: "/temp-list",
    component: React.lazy(() =>
      import("./termstemplate/tempList.jsx")
    ),
  },
  {
    path: "/update-temp/:tempId",
    component: React.lazy(() => import("./termstemplate/updateTemp.jsx")),
  },
  {
    path: "/create-salesmen",
    component: React.lazy(() => import("./salesman/createSalesman.jsx")),
  },
  {
    path: "/salesmen-list",
    component: React.lazy(() => import("./salesman/salesmenList.jsx")),
  },
  {
    path: "/update-salesmen/:salesId",
    component: React.lazy(() => import("./salesman/updateSalesmen.jsx")),
  },
  {
    path: "/convert-agreement-job/:agId",
    component: React.lazy(() => import("./agreement/agreementToJob.jsx")),
  },
  {
    path: "/company-report",
    component: React.lazy(() => import("./reports/cWiseReport.jsx")),
  },
  {
    path: "/product-report",
    component: React.lazy(() => import("./reports/productWiseReport.jsx")),
  },
  {
    path: "/agreement-report",
    component: React.lazy(() => import("./reports/agreementWise.jsx")),
  },
  {
    path: "/technician-report",
    component: React.lazy(() => import("./reports/technicianWise.jsx")),
  },
  {
    path: "/salesmen-report",
    component: React.lazy(() => import("./reports/salesMenWise.jsx")),
  },
  {
    path: "/print-barcode",
    component: React.lazy(() => import("./product/barCodePrinter.jsx")),
  },
 
];

export default pagesRoutes;
