import Axios from "axios";
let token = window.localStorage.getItem("accessToken") || "";

const axios = Axios.create({

  // baseURL: `https://api.believerssign.com/api/v1`,

  // baseURL:
  //   process.env.NODE_ENV === "development"
  //     ? `https://api.reseller.b2gsoft.xyz/api/v1`
  //     : `https://api.believerssign.com/api/v1`,
  
  // baseURL: `http://192.168.0.242:4335/api/v1`,

  baseURL: `https://api-arab.b2gsoft.com/api/v1`,

  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    
  },
 
});

export default axios;
